<template>
  <div class="hello">
    <h1>Her skjedde det noe rart, prøv å trykke på denne linken for å se om det løser problemet!</h1>
    <a href="?Luke=1" style="font-size: xx-large">🤞🤞Fiks problemet!🤞🤞</a>

    <br/>
    <br/>
    <span style="margin-left: 10px;margin-right: 10px;">Dersom problemet ikke løser seg kan du sjekke med <a href="mailto:PetterEAnderssen@gmail.com">Petter</a> om det er noe han kan fikse!</span>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    date: Date,
    luke: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

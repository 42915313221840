function attachToPlayer(){
    let videoPlayer = document.getElementById("videoPlayer");

    if(videoPlayer){
        console.log("Found player!");
    }
}

document.addEventListener("DOMContentLoaded", function(){
    attachToPlayer();
});

<template>
  <div id="app" style="margin-top: 0">
    <div class="snowflakes" aria-hidden="true">
      <div class="snowflake">
        🎅
      </div>
      <div class="snowflake">
        🦌
      </div>
      <div class="snowflake">
        ❄️
      </div>
      <div class="snowflake">
        ❄
      </div>
      <div class="snowflake">
        🎄
      </div>
      <div class="snowflake">
        ❆
      </div>
      <div class="snowflake">
        ❄
      </div>
      <div class="snowflake">
        😁
      </div>
      <div class="snowflake">
        ❆
      </div>
      <div class="snowflake">
        ❄
      </div>
      <div class="snowflake">
        ❄
      </div>
      <div class="snowflake">
        ❄
      </div>
      <div class="snowflake">
        ❄
      </div>
    </div>
    <div
        style="width: 100%;
        min-height: 50px;
        background: aliceblue;
        font-family: 'Mountains of Christmas', cursive;
        font-size: 2em;
        margin-bottom: 0;">
      <div class="dropdown"
          style="display: inline-block; position: absolute; left: 0; text-align: left; width: 100%">
        <button style="margin-left: 10px;background: transparent; border: none" onclick="myFunction()" class="dropbtn">
        <font-awesome-icon :icon="['fa', 'bars']" size="lg" />
        </button>
        <div id="myDropdown" class="dropdown-content">
          <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;font-size: 0.45em;">
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 1" href="?Luke=1">Luke 1</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 2" href="?Luke=2">Luke 2</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 3" href="?Luke=3">Luke 3</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 4" href="?Luke=4">Luke 4</a>
            <a style="border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 5" href="?Luke=5">Luke 5</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 6" href="?Luke=6">Luke 6</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 7" href="?Luke=7">Luke 7</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 8" href="?Luke=8">Luke 8</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 9" href="?Luke=9">Luke 9</a>
            <a style="border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 10" href="?Luke=10">Luke 10</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 11" href="?Luke=11">Luke 11</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 12" href="?Luke=12">Luke 12</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 13" href="?Luke=13">Luke 13</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 14" href="?Luke=14">Luke 14</a>
            <a style="border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 15" href="?Luke=15">Luke 15</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 16" href="?Luke=16">Luke 16</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 17" href="?Luke=17">Luke 17</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 18" href="?Luke=18">Luke 18</a>
            <a style="border-right: black 1px solid;border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 19" href="?Luke=19">Luke 19</a>
            <a style="border-bottom: black 1px solid" v-if="luke.maksLukeNr >= 20" href="?Luke=20">Luke 20</a>
            <a style="border-right: black 1px solid" v-if="luke.maksLukeNr >= 21" href="?Luke=21">Luke 21</a>
            <a style="border-right: black 1px solid" v-if="luke.maksLukeNr >= 22" href="?Luke=22">Luke 22</a>
            <a style="border-right: black 1px solid" v-if="luke.maksLukeNr >= 23" href="?Luke=23">Luke 23</a>
            <a style="border-right: black 1px solid" v-if="luke.maksLukeNr >= 24" href="?Luke=24">Luke 24</a>
          </div>
        </div>
      </div>
      <h3 style="margin-top: 0; padding-top: 0;display: inline;"> Turnjul!</h3>
    </div>
    <Luke style="margin-top: 0;padding-top: 0" v-if="luke.lukeNr === 0" msg="Luke 0" :date="luke.date" :luke="luke"/>
    <Luke1 style="margin-top: 0;padding-top: 0" v-else-if="luke.lukeNr > 0" msg="Luke 2" :date="luke.date" :luke="luke"/>
  </div>
</template>

<script>
import Vue from 'vue'
import App from './App'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faBars } from '@fortawesome/pro-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Luke from './components/IngenLuke.vue'
import Luke1 from "@/components/Luke1";

library.add(faUserSecret)
library.add(faInstagram)
library.add(faBars)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
let uri = window.location.href.split('?');
let valgtLuke = -1;
if(uri.length > 1){
  console.log(uri[1]);
  if(uri[1].includes("Luke")){
    let valgtLukeArr = uri[1].split("=");
    if(valgtLukeArr.length > 1){
      console.log("valgtLuke: ",parseInt(valgtLukeArr[1]));
      valgtLuke = parseInt(valgtLukeArr[1]);
    }
  }
}
console.log(uri);
console.log(new Date(Date.now()));

export default {
  name: 'App',
  components: {
    Luke,
    Luke1
  },
  data(){
    //new Date(Date.now());//skal brukes i produksjon // new Date(Date.parse("12-01-2022 13:30:34")) //Denne faker jul, kopier og endre 01 for å skifte luke
    let today = new Date(Date.now());
    // let today = new Date(Date.parse("12-24-2022 13:30:34"));

    let month = today.getMonth()+1;
    let day = today.getDate();
    let december = 12; //should be 12
    let luke = 0;
    let maksLuke = 0;
    if (month === december){
      if(day < 24){
      }else{
        day = 24;
      }
      luke = day;
      maksLuke = day;

    }else if(today.getFullYear() > 2022){
      maksLuke = 24;
      luke = 1;
    }
    console.log(day);
     // luke = 8;

    if(valgtLuke !== -1 && valgtLuke <= maksLuke){
      luke = valgtLuke;
    }

    let luker = [
      {
        comment:"Luke 0",
        date: new Date(Date.now()),
        lukeNr: 0,
        maksLukeNr: maksLuke,
        treningBesk: 'testluke!',
        treningTittel: 'testluke',
        treningVideo: 'Luke-1.mp4',
        trenerBesk: 'Testluke',
        trenerNavn: 'Testluke',
        trenerBilde: 'Stian.jpg',
        trenerInstagram: 'stloe30',
        treningVideoPoster: 'testvideo-bilde.png'
      },
      {
        comment:"Luke 1",
        date: new Date(Date.now()),
        lukeNr: 1,
        maksLukeNr: maksLuke,
        treningBesk: 'I dag jobber vi med en forøvelse til svevsitt! øver du nok på denne; så har du den kanskje ila. jula! Anbefalte repetisjoner: 10 x 3',
        treningTittel: 'Forøvelse til svevsitt',
        treningVideo: 'Luke-1.mp4',
        trenerBesk: 'Stian er trener på rekrutt og junior i Sola Turn',
        trenerNavn: 'Stian Gabrielsen Loe',
        trenerBilde: 'Stian.jpg',
        trenerInstagram: 'stloe30',
        treningVideoPoster: 'Luke-1-bilde.png'
      },
      {
        comment:"Luke 2",
        date: new Date(Date.now()),
        lukeNr: 2,
        maksLukeNr: maksLuke,
        treningBesk: 'Her jobber vi med magestyrke, kan bruke en vekt; eller fylle en ransel med noe som hjemme-alternativ. Anbefalte repetisjoner: 3 x 20',
        treningTittel: 'Magetrening!',
        treningVideo: 'Luke-2.mp4',
        trenerBesk: 'Tom Rune er trener på rekrutt og junior i Sola Turn, og har også et ansvarsområde rundt skadeforebygging og teiping i klubben.',
        trenerNavn: 'Tom Rune (Teip) Tjelta',
        trenerBilde: 'TomRune.jpg',
        trenerInstagram: 'nana',
        treningVideoPoster: 'Luke-2-bilde.png'
      },
      {
        comment:"Luke 3",
        date: new Date(Date.now()),
        lukeNr: 3,
        maksLukeNr: maksLuke,
        treningBesk: 'Bak denne luken finner du en fin styrkeøvelse som hjelper med å innlære håndstående press. Anbefalte repetisjoner: 10 x 3',
        treningTittel: 'Forøvelse til press',
        treningVideo: 'Luke-3.mp4',
        trenerBesk: 'Stian er trener på rekrutt og junior i Sola Turn',
        trenerNavn: 'Stian Gabrielsen Loe',
        trenerBilde: 'Stian.jpg',
        trenerInstagram: 'stloe30',
        treningVideoPoster: 'Luke-3-bilde.png'
      },
      {
        comment:"Luke 4",
        date: new Date(Date.now()),
        lukeNr: 4,
        maksLukeNr: maksLuke,
        treningBesk: 'Hovedpoenget i dagens luke er å holde kjernen stabil gjennom hele øvelsen, ved å f.eks unngå å svaie ryggen eller bevege hofta. Øvelsen er dermed god trening for kjernemuskulatur, men også for skuldre, og generelt for å forbedre håndstående. Øvelsen kan også hjelpe mot å lære seg press til håndstående. Fornuftig antall i en treningsøkt: alt hva du er komfortabel med, men f.eks 5×3 av denne kan være et godt innskudd i et treningsprogram.',
        treningTittel: 'Kjernemuskulatur og skuldre',
        treningVideo: 'Luke-4.mp4',
        trenerBesk: '22 år gammel og kommer fra Sandnes, hvor han har turnet i flere år. Nå bor han i Oslo hvor han studerer og turner for Oslo Turn sitt senior herrelag i Teamgym. Han er trener for aspirant og rekrutt i Oslo Turn.',
        trenerNavn: 'Kjartan Frafjord',
        trenerBilde: 'Kjartan.jpg',
        trenerInstagram: 'nana',
        treningVideoPoster: 'Luke-4-bilde.png'
      },
      {
        comment:"Luke 5",
        date: new Date(Date.now()),
        lukeNr: 5,
        maksLukeNr: maksLuke,
        treningBesk: 'I dagens uke lærer vi hvordan vi gjør forover rulle.',
        treningTittel: 'Forover rulle!',
        treningVideo: 'Luke-5.mp4',
        trenerBesk: 'Aleksander er trener i Grimstad Turn.',
        trenerNavn: 'Aleksander Martens',
        trenerBilde: 'Aleksander.png',
        trenerInstagram: 'aleksandermartens',
        treningVideoPoster: 'Luke-5-bilde.png'
      },
      {
        comment:"Luke 6",
        date: new Date(Date.now()),
        lukeNr: 6,
        maksLukeNr: maksLuke,
        treningBesk: 'I dagens luke jobber vi med å forsterke ankler, slik at vi unngår skader.',
        treningTittel: 'Ankeltrening!',
        treningVideo: 'Luke-6.mp4',
        trenerBesk: 'Tom Rune er trener på rekrutt og junior i Sola Turn, og har også et ansvarsområde rundt skadeforebygging og teiping i klubben.',
        trenerNavn: 'Tom Rune (Teip) Tjelta',
        trenerBilde: 'TomRune.jpg',
        trenerInstagram: 'nana',
        treningVideoPoster: 'Luke-6-bilde.png'
      },
      {
        comment:"Luke 7",
        date: new Date(Date.now()),
        lukeNr: 7,
        maksLukeNr: maksLuke,
        treningBesk: 'I dagens luke jobber vi med å styrke skuldrene våre! Sterke skuldre trengs for å bli god i det meste i turn.',
        treningTittel: 'Skulderstyrke',
        treningVideo: 'Luke-7.mp4',
        trenerBesk: 'Stian er trener på rekrutt og junior i Sola Turn',
        trenerNavn: 'Stian Gabrielsen Loe',
        trenerBilde: 'Stian.jpg',
        trenerInstagram: 'stloe30',
        treningVideoPoster: 'Luke-7-bilde.png'
      },
      {
        comment:"Luke 8",
        date: new Date(Date.now()),
        lukeNr: 8,
        maksLukeNr: maksLuke,
        treningBesk: 'Bak dagens luke jobber vi med en spennende øvelse, alternativ til skumklosser er doruller, eller andre lette ting man kan løfte og stable med beina!',
        treningTittel: 'Magetrening!',
        treningVideo: 'Luke-8.mp4',
        trenerBesk: 'Tom Rune er trener på rekrutt og junior i Sola Turn, og har også et ansvarsområde rundt skadeforebygging og teiping i klubben.',
        trenerNavn: 'Tom Rune (Teip) Tjelta',
        trenerBilde: 'TomRune.jpg',
        trenerInstagram: 'nana',
        treningVideoPoster: 'Luke-8-bilde.png'
      },
      {
        comment:"Luke 9",
        date: new Date(Date.now()),
        lukeNr: 9,
        maksLukeNr: maksLuke,
        treningBesk: 'I dagens luke har Stian en spennede utfordring til dere! ta en titt da vel!',
        treningTittel: 'Utfordring!',
        treningVideo: 'Luke-9.mp4',
        trenerBesk: 'Stian er trener på rekrutt og junior i Sola Turn',
        trenerNavn: 'Stian Gabrielsen Loe',
        trenerBilde: 'Stian.jpg',
        trenerInstagram: 'stloe30',
        treningVideoPoster: 'Luke-9-bilde.png'
      },
      {
        comment:"Luke 10",
        date: new Date(Date.now()),
        lukeNr: 10,
        maksLukeNr: maksLuke,
        treningBesk: 'Bak dagens luke skal vi prøve diamant og russiske armhevinger!',
        treningTittel: 'Armhevingsutfordringer',
        treningVideo: 'Luke-10.mp4',
        trenerBesk: 'Tom Rune er trener på rekrutt og junior i Sola Turn, og har også et ansvarsområde rundt skadeforebygging og teiping i klubben.',
        trenerNavn: 'Tom Rune (Teip) Tjelta',
        trenerBilde: 'TomRune.jpg',
        trenerInstagram: 'nana',
        treningVideoPoster: 'Luke-10-bilde.png'
      },
      {
        comment:"Luke 11",
        date: new Date(Date.now()),
        lukeNr: 11,
        maksLukeNr: maksLuke,
        treningBesk: 'Klarer du gjennomføre rytmesekvensen Bjørg Elin viser frem her!?',
        treningTittel: 'Rytmeutfordring',
        treningVideo: 'Luke-11.mp4',
        trenerBesk: 'Bjørg Elin er Trener på Apparat turn i Sandnes Turn, og Jr/Sr Tropp hos Sandved Turn',
        trenerNavn: 'Bjørg Elin Nærland',
        trenerBilde: 'bjorg.png',
        trenerInstagram: 'beflikkflakk',
        treningVideoPoster: 'Luke-11-bilde.png'
      },
      {
        comment:"Luke 12",
        date: new Date(Date.now()),
        lukeNr: 12,
        maksLukeNr: maksLuke,
        treningBesk: 'Prøv øvelsene til Aleksander for å øve på landinger! kjør igjennom til du er fornøyd og har kontroll.',
        treningTittel: 'Landinger',
        treningVideo: 'Luke-12.mp4',
        trenerBesk: 'Aleksander er trener i Grimstad Turn.',
        trenerNavn: 'Aleksander Martens',
        trenerBilde: 'Aleksander.png',
        trenerInstagram: 'aleksandermartens',
        treningVideoPoster: 'Luke-12-bilde.png'
      },
      {
        comment:"Luke 13",
        date: new Date(Date.now()),
        lukeNr: 13,
        maksLukeNr: maksLuke,
        treningBesk: 'Se om du får til å utføre piruetten Bjørg Elin viser frem! Få foten stabilt over 90 grader, ellers gjelder de vanlige piruett reglene (opp på tå hele piruetten, kontroll i utførelse, osv)',
        treningTittel: 'Piruettutfordring',
        treningVideo: 'Luke-13.mp4',
        trenerBesk: 'Bjørg Elin er Trener på Apparat turn i Sandnes Turn, og Jr/Sr Tropp hos Sandved Turn',
        trenerNavn: 'Bjørg Elin Nærland',
        trenerBilde: 'bjorg.png',
        trenerInstagram: 'beflikkflakk',
        treningVideoPoster: 'Luke-13-bilde.png'
      },
      {
        comment:"Luke 14",
        date: new Date(Date.now()),
        lukeNr: 14,
        maksLukeNr: maksLuke,
        treningBesk: 'Øv på armsving sammen med Aleksander! jobb til du føler du har kontroll på å svinge armene slik at de er på topp når de skal. (merk forskjellen på salto og strekkhopp på timingen)',
        treningTittel: 'Innhopp',
        treningVideo: 'Luke-14.mp4',
        trenerBesk: 'Aleksander er trener i Grimstad Turn.',
        trenerNavn: 'Aleksander Martens',
        trenerBilde: 'Aleksander.png',
        trenerInstagram: 'aleksandermartens',
        treningVideoPoster: 'Luke-14-bilde.png'
      },
      {
        comment:"Luke 15",
        date: new Date(Date.now()),
        lukeNr: 15,
        maksLukeNr: maksLuke,
        treningBesk: 'Se om du får til øvelsen Bjørg Elin demonstrerer her! kan være en fordel å gjøre til noe relativt mykt. husk å ta i mot med den bøyde foten, slik at vi ikke slår den utstrekte for hardt i bakken.',
        treningTittel: 'Sittesalto med hånd nedi',
        treningVideo: 'Luke-15.mp4',
        trenerBesk: 'Bjørg Elin er Trener på Apparat turn i Sandnes Turn, og Jr/Sr Tropp hos Sandved Turn',
        trenerNavn: 'Bjørg Elin Nærland',
        trenerBilde: 'bjorg.png',
        trenerInstagram: 'beflikkflakk',
        treningVideoPoster: 'Luke-15-bilde.png'
      },
      {
        comment:"Luke 16",
        date: new Date(Date.now()),
        lukeNr: 16,
        maksLukeNr: maksLuke,
        treningBesk: 'Følg Aleksander igjennom dips! Kjør gjerne 15 x 3 (med litt pause mellom settene), eller tilpass mengden til det nivået du er på for øyeblikket.',
        treningTittel: 'Dips',
        treningVideo: 'Luke-16.mp4',
        trenerBesk: 'Aleksander er trener i Grimstad Turn.',
        trenerNavn: 'Aleksander Martens',
        trenerBilde: 'Aleksander.png',
        trenerInstagram: 'aleksandermartens',
        treningVideoPoster: 'Luke-16-bilde.png'
      },
      {
        comment:"Luke 17",
        date: new Date(Date.now()),
        lukeNr: 17,
        maksLukeNr: maksLuke,
        treningBesk: 'Bjørg Elin demonsterer Ormen/Slangen, se om du får det til da! altid populært på dansegulvet eller om man vil ha det gøy på oppvarming.',
        treningTittel: 'Ormen',
        treningVideo: 'Luke-17.mp4',
        trenerBesk: 'Bjørg Elin er Trener på Apparat turn i Sandnes Turn, og Jr/Sr Tropp hos Sandved Turn',
        trenerNavn: 'Bjørg Elin Nærland',
        trenerBilde: 'bjorg.png',
        trenerInstagram: 'beflikkflakk',
        treningVideoPoster: 'Luke-17-bilde.png'
      },
      {
        comment:"Luke 18",
        date: new Date(Date.now()),
        lukeNr: 18,
        maksLukeNr: maksLuke,
        treningBesk: 'Aleksander forklarer generelle begreper og tankesett rundt spurt, prøv å gjøre øvelsene, og test mens du utfører noen spurter. Pass på å ha underlag du ikke sklir på!',
        treningTittel: 'Spurt',
        treningVideo: 'Luke-18.mp4',
        trenerBesk: 'Aleksander er trener i Grimstad Turn.',
        trenerNavn: 'Aleksander Martens',
        trenerBilde: 'Aleksander.png',
        trenerInstagram: 'aleksandermartens',
        treningVideoPoster: 'Luke-18-bilde.png'
      },
      {
        comment:"Luke 19",
        date: new Date(Date.now()),
        lukeNr: 19,
        maksLukeNr: maksLuke,
        treningBesk: 'Bjørg Elin demonsterer vendinger i håndstående, se om du klarer flere enn henne! Et godt tips er å vente med å starte vendingen til du er helt oppe å står på hender.',
        treningTittel: 'Vendinger',
        treningVideo: 'Luke-19.mp4',
        trenerBesk: 'Bjørg Elin er Trener på Apparat turn i Sandnes Turn, og Jr/Sr Tropp hos Sandved Turn',
        trenerNavn: 'Bjørg Elin Nærland',
        trenerBilde: 'bjorg.png',
        trenerInstagram: 'beflikkflakk',
        treningVideoPoster: 'Luke-19-bilde.png'
      },
      {
        comment:"Luke 20",
        date: new Date(Date.now()),
        lukeNr: 20,
        maksLukeNr: maksLuke,
        treningBesk: 'I dagens luke jobber vi med styrking av muskler i beina våre, 20 x 3 tåhev og 15 x 3 knebøy er et enkelt forslag til antall, men juster etter behov.',
        treningTittel: 'Beinstyrke',
        treningVideo: 'Luke-20.mp4',
        trenerBesk: 'Aleksander er trener i Grimstad Turn.',
        trenerNavn: 'Aleksander Martens',
        trenerBilde: 'Aleksander.png',
        trenerInstagram: 'aleksandermartens',
        treningVideoPoster: 'Luke-20-bilde.png'
      },
      {
        comment:"Luke 21",
        date: new Date(Date.now()),
        lukeNr: 21,
        maksLukeNr: maksLuke,
        treningBesk: 'Se om du får til denne øvelsen!',
        treningTittel: 'Håndstående på én arm med halv vending',
        treningVideo: 'Luke-21.mp4',
        trenerBesk: 'Bjørg Elin er Trener på Apparat turn i Sandnes Turn, og Jr/Sr Tropp hos Sandved Turn',
        trenerNavn: 'Bjørg Elin Nærland',
        trenerBilde: 'bjorg.png',
        trenerInstagram: 'beflikkflakk',
        treningVideoPoster: 'Luke-21-bilde.png'
      },
      {
        comment:"Luke 22",
        date: new Date(Date.now()),
        lukeNr: 22,
        maksLukeNr: maksLuke,
        treningBesk: 'I dagens luke kan du bli med tom rune på en god økt for å styrke skuldrene våre! jobb etter nivå.',
        treningTittel: 'Skuldertrening!',
        treningVideo: 'Luke-22.mp4',
        trenerBesk: 'Tom Rune er trener på rekrutt og junior i Sola Turn, og har også et ansvarsområde rundt skadeforebygging og teiping i klubben.',
        trenerNavn: 'Tom Rune (Teip) Tjelta',
        trenerBilde: 'TomRune.jpg',
        trenerInstagram: 'nana',
        treningVideoPoster: 'Luke-22-bilde.png'
      },
      {
        comment:"Luke 23",
        date: new Date(Date.now()),
        lukeNr: 23,
        maksLukeNr: maksLuke,
        treningBesk: 'I dagens luke jobber vi med en forøvelse til planke uten albuer i magen! Aspirerer du til å kunne gjøre planke uten å støtte deg på albuer, så er dette en kjempeøvelse å jobbe med.',
        treningTittel: 'Planke/Planche',
        treningVideo: 'Luke-23.mp4',
        trenerBesk: 'Tom Rune er trener på rekrutt og junior i Sola Turn, og har også et ansvarsområde rundt skadeforebygging og teiping i klubben.',
        trenerNavn: 'Tom Rune (Teip) Tjelta',
        trenerBilde: 'TomRune.jpg',
        trenerInstagram: 'nana',
        treningVideoPoster: 'Luke-23-bilde.png'
      },
      {
        comment:"Luke 24",
        date: new Date(Date.now()),
        lukeNr: 24,
        maksLukeNr: maksLuke,
        treningBesk: 'Julaften er her! </br> Og det blir ikke en skikkelig julaften uten litt turn! </br>I dagens luke viser Tom Rune oss en øvelse du kan kombinere med åpning av pakker! </br></br>Hvis du vil være med i trekningen av den kule t-skjorta Tom Rune viser til, så trenger du bare tagge <a href="https://instagram.com/campsola" target="_blank">Camp Sola</a> sin instagram i en ny turnrelatert post! se instagram link under for  å finne Instaen! </br>Bruk gjerne muligheten til å filme deg selv eller kanskje helst foreldre som prøver seg på øvelsen i mens de åpner noen gaver eller uten gaver!',
        treningTittel: 'Pakkeåpning!',
        treningVideo: 'Luke-24.mp4',
        trenerBesk: 'Tom Rune er trener på rekrutt og junior i Sola Turn, og har også et ansvarsområde rundt skadeforebygging og teiping i klubben.',
        trenerNavn: 'Tom Rune (Teip) Tjelta',
        trenerBilde: 'TomRune.jpg',
        trenerInstagram: 'campsola',
        treningVideoPoster: 'Luke-24-bilde.png'
      }
    ]
    return {
      luke:luker[luke],
      // luke:luker[4],

    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* customizable snowflake styling */
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial,serif;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}
/* Demo Purpose Only*/
.demo {
  font-family: 'Raleway', sans-serif;
  color:#fff;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;
}
.demo a{
  font-family: 'Raleway', sans-serif;
  color: #000;
}

/* Dropdown Button */
.dropbtn {
  /*background-color: #3498DB;*/
  /*color: white;*/
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  /*background-color: #2980B9;*/
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
</style>

<template>
  <div class="hello" style="text-align: left;margin-top: 0; padding-top: 0;">
      <h1 style="color: white; margin-left: 20px; margin-top: 0;
    margin-bottom: 5px; padding-top: 0;">Luke {{ luke.lukeNr }}</h1>
<!--      <h1>{{ date }}</h1>-->
<!--      <h1>{{ luke.lukeNr }}</h1>-->
      <video controls controlslist="nodownload" disablepictureinpicture id="videoPlayer"  style="width:100%; max-height: 400px;" :poster="require(`@/assets/bilder/videoposters/${luke.treningVideoPoster}`)">
        <source :src="require(`@/assets/videoer/${luke.treningVideo}`)" type="video/mp4">
        Sorry, your browser doesn't support embedded videos.
      </video>

<div style="max-width: 500px;margin-left: auto; margin-right: auto; color: white; padding-left: 10px; padding-right: 10px;">
  <div  style="text-align: left">
    <h3 style="margin-top: 0">{{ luke.treningTittel }} </h3>
    <p v-html="luke.treningBesk">

    </p>
  </div>

</div>


<div style="max-width: 500px; text-align: center; margin-left: auto; margin-right: auto; border: 2px white dotted; padding: 15px">
    <div style="display:grid; grid-template-columns: 1fr 1fr;">
      <div>
        <img :src="require(`@/assets/bilder/trenere/${luke.trenerBilde}`)" style="max-width: 200px" alt="Trener">
      </div>
      <div style="text-align: left; padding: 15px; color: white">
        <h3 style="margin-top: 0">{{ luke.trenerNavn }}</h3>
        <p>{{ luke.trenerBesk }}</p>
      </div>
      <div v-if="luke.trenerInstagram !== 'nana'" style="text-align: left; margin-top: 15px;">
        <a :href="'https://www.instagram.com/'+luke.trenerInstagram+'/'" target="_blank" style="color: white">
          <span><font-awesome-icon :icon="['fab', 'instagram']" /></span> <span>Instagram!</span>
        </a>
      </div>
    </div>
</div>

  </div>
</template>

<script>
require('../assets/scripts/videocontrol.js');

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    date: Date,
    luke: Object,
    treningBesk: String,
    treningTittel: String,
    treningVideo: String,
    treningVideoPoster: String,
    trenerBesk: String,
    trenerNavn: String,
    trenerBilde: String,
    trenerInstagram: String
  },


}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
